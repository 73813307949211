import {useEffect, useRef, useState} from "react";
import useOmniaApi from "./use-omnia-api";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {addEndpointToList, removeEndpointFromList} from "../store/actions/datatable-actions";


function UsePagination(props){
    /**
     * @name: usePagination
     * @description: This hook is used to paginate the results of a given endpoint
     * @param endpoint: string
     * @param page: number
     * @param append: boolean, new elements will be added when true
     * @param query: object TODO: might cause problems when reloading based on query. Memo()?
     * @param pageSize: number
     * @return {{elements: [], pages: number, count: number, loading: boolean}}
     */

    const {endpoint, page, query = {}, pageSize = 10, append = false, updateListener = null} = props;

    const { get } = useOmniaApi();
    const [ elements, setElements ] = useState([]);
    const [ pages, setTotalPages ] = useState(1);
    const [ count, setCount ] = useState(0);
    const updates = useSelector(state => state.datatable.updates);
    const [ initialLoading, setInitialLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const dispatch = useDispatch();
    const requestIdRef = useRef(0);

    // A variable stating that there is more to load
    const isMore = pages > page && elements.length > 0;

    // This is the central function to load the details of the current page
    const loadData = (reset, withLoading) => {

        // Increment the request ID
        const currentRequestId = ++requestIdRef.current; // Increment request ID

        // Set loading
        if(withLoading)
            setLoading(true);

        // Total pages amount could be zero
        const finalPage = pages === 0 ? 1 : pages;

        // Load the details
        get(endpoint, {...query, ...{page: reset ? 1 : (page > pages ? finalPage : (page === 0 ? 1 : page)), size: pageSize}}).then(response => {
            if (currentRequestId === requestIdRef.current) {
                setCount(response.count);

                // Check for append mode
                if (append && !reset) {
                    setElements(prev => prev.concat(response.results.filter(r => !prev.map(p => p.id).includes(r.id))));
                } else {
                    setElements(response.results);
                }

                setTotalPages(Math.ceil(response.count / pageSize));
            }
        }).finally(() => {
            if (currentRequestId === requestIdRef.current) {
                setLoading(false);
                setInitialLoading(false);
            }
        })
    }

    const reload = () => {
        loadData(false, true);
    }

    const reset = () => {
        loadData(true, true);
    }

    useEffect(()=>{
        dispatch(addEndpointToList(endpoint))
        return()=>{
            dispatch(removeEndpointFromList(endpoint))
        };
    },[endpoint])

    useEffect(() => {

        // Load the details
        loadData(false, false);

    }, [page, pageSize, endpoint]);

    useEffect(() => {

        // Load the details
        loadData(true, false);

    }, [updates?.[updateListener || endpoint]]);

    useEffect(() => {

        // Load the details with a hard reset (query changed)
        if(!initialLoading){
            loadData(true, true);
        }

    }, [JSON.stringify(query)]);

    return {
        elements,
        isMore,
        pages,
        count,
        loading,
        initialLoading,
        setElements,
        reload,
        reset
    }

}

UsePagination.propTypes = {
    page: PropTypes.number.isRequired,
    endpoint: PropTypes.string.isRequired,
    query: PropTypes.object,
    pageSize: PropTypes.number,
    updateListener: PropTypes.string
}

UsePagination.defaultProps = {
    query: {},
    pageSize: 10,
    updateListener: null
}

export default UsePagination;