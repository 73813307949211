import React, {useEffect, useState} from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import {Badge, IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {NotificationsPopover} from './notifications-popover';
import useApi from "../../../../hooks/use-api";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setUnreadNotifications} from "../../../../store/actions/account-actions";
import usePagination from "../../../../hooks/use-pagination";

export const NotificationsButton = () => {
    const popover = usePopover();
    const { t } = useTranslation();
    const {post} = useApi();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const unread = useSelector(state => state.account.unread);
    const { elements, isMore, setElements, loading, reset } = usePagination({
        endpoint: 'notifications',
        page: currentPage,
        append: true,
        query: {ordering: '-created_at'},
        pageSize: 10
    });

    const handleMarkAsRead = (notification) => {
        setElements(prev => prev.map(n => n.id === notification.id ? {...n, read: true} : n));
    };

    const handleMarkAsUnread = (notification) => {
        setElements(prev => prev.map(n => n.id === notification.id ? {...n, read: false} : n));
    };

    const handleMarkAllAsRead = () => {
        post('notifications/mark_all_as_read').then(() => {
            setElements(prev => prev.map(notification => ({...notification, read: true})));
        });
    };

    const handleDeleteNotification = (notification) => {
        setElements(prev => prev.filter(n => n.id !== notification.id));
    };

    const handleDeleteAll = () => {
        dispatch(setUnreadNotifications(0));
        post('notifications/delete_all').then(() => {
            setElements([]);
        });
    };

    const loadMore = () => {
        setCurrentPage(prev => prev + 1);
    };

    useEffect(() => {
        if(popover.open){
            setCurrentPage(1);
            reset();
        }
    }, [popover.open]);

    return (
        <>
            <Tooltip title={t("common.notifications")}>
                <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <Badge color="error" badgeContent={unread}>
                        <SvgIcon>
                            <Bell01Icon/>
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                notifications={elements}
                onRemoveAll={handleDeleteAll}
                onClose={popover.handleClose}
                loading={loading}
                onLoadMore={loadMore}
                onMarkAsRead={handleMarkAsRead}
                onMarkAsUnread={handleMarkAsUnread}
                onRemove={handleDeleteNotification}
                onMarkAllAsRead={handleMarkAllAsRead}
                open={popover.open}
                moreExists={isMore}
            />
        </>
    );
};

