import React from 'react';
import PropTypes from 'prop-types';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/CpuChip02';
import ConfigurationIcon from '@untitled-ui/icons-react/build/esm/Tool02';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users01';
import {
    Box,
    Button,
    Chip,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    SvgIcon, Tooltip,
    Typography
} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import { useNavigate } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {logout, setIsTransitioning} from "../../../../store/actions/account-actions";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import OnIcon from "../../../elements/icon";
import {APP_SETTING} from "../../../../../setup";

export const AccountPopover = (props) => {
    const {anchorEl, onClose, open, ...other} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {hasRights} = useSecurityCheck();
    const user = useSelector((state) => state.account.user);
    const { t } = useTranslation();

    const handleLogout = () => {
        dispatch(setIsTransitioning(true));
        setTimeout(() => {
            dispatch(logout());
            navigate('/login');
            setTimeout(() => {
                dispatch(setIsTransitioning(false));
            }, 500);
        }, 500);
    }

    if(!user)
        return null;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={!!open}
            slotProps={{
                paper: {
                    sx: {
                        width: 180,
                    }
                }
            }}
            {...other}
        >
            <Box sx={{p: 2}}>
                <Typography variant="h6">
                    {user.name}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {user.position}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{p: 1}}>
                <Tooltip title={t('common.tooltips.profile')} placement="left">
                    <ListItemButton
                        component={RouterLink}
                        href={'/groon/profile'}
                        onClick={onClose}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <OnIcon iconName="User01" size="small"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography variant="body1">
                                    {t("layout.profile")}
                                </Typography>
                            )}
                        />
                    </ListItemButton>
                </Tooltip>
                {user.user_group && (
                    <Tooltip title={t('common.tooltips.group')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/team/' + user.user_group?.id}
                            onClick={onClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon fontSize="small">
                                    <Users03Icon/>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("layout.group")}
                                    </Typography>
                                )}
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasRights("company_access_right") && (
                    <Tooltip title={t('common.tooltips.organization')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/organization'}
                            onClick={onClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon fontSize="small">
                                    <OnIcon iconName="Building07" />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("layout.company")}
                                    </Typography>
                                )}
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasRights("my_workflows") && (
                    <Tooltip title={t('common.tooltips.processes')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/workflows'}
                            onClick={onClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="Wind02" size="small"/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("layout.processes")}
                                    </Typography>
                                )}
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasRights('admin_area') && (
                    <Tooltip title={t('common.tooltips.control')} placement="left">
                        <ListItemButton
                            component={RouterLink}
                            href={'/groon/control'}
                            onClick={onClose}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="Speedometer03" size="small"/>
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography variant="body1">
                                        {t("layout.management")}
                                    </Typography>
                                )}
                            />
                            {/*<Chip label={requestsCount} size="small" />*/}
                        </ListItemButton>
                    </Tooltip>
                )}
                <Tooltip title={t('common.tooltips.configuration')} placement="left">
                    <ListItemButton
                        component={RouterLink}
                        href={'/groon/control/settings'}
                        onClick={onClose}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <OnIcon iconName="Settings02" size="small"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography variant="body1">
                                    {t("layout.configuration")}
                                </Typography>
                            )}
                        />
                    </ListItemButton>
                </Tooltip>
            </Box>
            <Divider sx={{my: '0 !important'}}/>
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    fullWidth
                    color="inherit"
                    onClick={handleLogout}
                    size="small"
                >
                    {t("layout.logout")}
                </Button>
            </Box>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
