import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, Grid, Stack, TextField, Typography} from "@mui/material";
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../hooks/use-is-mobile";

function FileRenameDialog({file, open, handleClose, onSuccess}) {

    const [newName, setNewName] = useState('');
    const {put} = useApi();
    const {t} = useTranslation();
    const { isMobile } = useIsMobile();

    const renameFile = () => {
        put('files', {...file, ...{name: newName}}).then((updated) => {
            if (onSuccess)
                onSuccess(updated);
            handleClose();
        });
    }

    useEffect(() => {
        setNewName(file.name);
    }, [file]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            fullScreen={isMobile}
            aria-labelledby="form-dialog-title"
        >
            <DialogContent>
                <Typography variant="h4" color="textPrimary" sx={{mb: 4}}>
                    {t("common.edit")}
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    onChange={(event) => {
                        setNewName(event.target.value);
                    }}
                    id="name"
                    value={newName}
                    label={t("attributes.name")}
                    type="text"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={renameFile} variant="contained" color="primary">
                        {t("common.save")}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

FileRenameDialog.propTypes = {
    file: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onSuccess: PropTypes.func
}

export default FileRenameDialog;