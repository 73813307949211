import React, {useState, useEffect, useRef} from "react";
import {Box, CircularProgress, Container, List, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import useSocket from "src/omnia/hooks/use-socket";
import produce from "immer";
import moment from "moment";
import Message from "./chat-assistant-message";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/lab";
import {useSelector} from "react-redux";

function ChatAssistantViewer({chatId, smallVersion, onAssistantNameChange, onUpdate, height, maxWidth, ...rest}) {

    const {t} = useTranslation();
    const [name, setName] = useState("GROON Assistent");
    const [initial, setInitial] = useState(true);
    const [messages, setMessages] = useState([]);
    const [messageChunks, setMessageChunks] = useState(null);
    const [canStream, setCanStream] = useState(false);
    const [typing, setTyping] = useState([]);
    const [thinking, setThinking] = useState([]);
    const [building, setBuilding] = useState([]);
    const messagesEndRef = useRef(null);
    const user = useSelector(state => state.account.user);

    const chat = chatId ? chatId : user?.current_assistant_chat || null;

    const appendMessages = (newMessages) => {
        setMessages(prev => produce(prev.concat(newMessages.filter(m => !prev.map(pm => pm.id).includes(m.id))), draft => {
            draft.sort((a, b) => (new Date(b.sent_at)).getTime() < (new Date(a.sent_at)).getTime() ? 1 : -1);
        }));
        setTimeout(() => {
            setInitial(false);
        }, 2000);
    }

    const scrollDown = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'instant'});
        }
    }

    const {isConnected} = useSocket('assistant/' + chat, (data) => {

        if (data['kind'] === 'messages') {
            appendMessages(data['data']);
        }

        if( data['kind'] === 'message-chunk'){
            setMessageChunks(prev => {
                if(prev === null){
                    return data['chunk'];
                } else {
                    return prev + data['chunk'];
                }
            })
            scrollDown();
        }

        if (data['kind'] === 'assistant_name') {
            setName(data['data']);
            onAssistantNameChange?.(data['data']);
        }

        if (data['kind'] === 'message') {
            appendMessages([data['data']]);
            setMessageChunks(null);
        }

        if (data['kind'] === 'model_can_stream') {
            setCanStream(data['data']);
        }

        if (data['kind'] === 'start-thinking') {
            setThinking(prev => {
                if (!prev.includes(data['data'])) {
                    return prev.concat(data['data']);
                }
                return prev;
            });
        }

        if (data['kind'] === 'start-building') {
            setBuilding(prev => {
                if (!prev.includes(data['data'])) {
                    return prev.concat(data['data']);
                }
                return prev;
            });
        }

        if (data['kind'] === 'stop-thinking') {
            setThinking(prev => prev.filter(t => t !== data['data']));
        }

        if (data['kind'] === 'stop-building') {
            setBuilding(prev => prev.filter(t => t !== data['data']));
        }

        if (data['kind'] === 'start-typing') {
            setTyping(prev => {
                if (!prev.includes(data['data'])) {
                    return prev.concat(data['data']);
                }
                return prev;
            });
        }

        if (data['kind'] === 'stop-typing') {
            setTyping(prev => prev.filter(t => t !== data['data']));
        }

        if (data['kind'] === 'chat') {
            if (onUpdate) {
                onUpdate(data['data']);
            }
        }

    }, true);

    useEffect(() => {
        scrollDown();
    }, [messages, typing]);

    if (!isConnected) {
        return (
            <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </div>
        )
    }

    const messageStack = (
        <Stack spacing={0.2} sx={{maxWidth: '100%'}}>
            {messages.map((message, index) => (
                <Message
                    key={'message-' + index}
                    index={index}
                    omitAnimation={canStream}
                    initial={initial}
                    smallVersion={smallVersion}
                    assistantName={name}
                    message={message}
                    lastUserMessage={
                        (index === messages.length - 1) ||
                        (
                            (messages[index].sender?.id !== messages[index + 1].sender?.id) ||
                            (
                                (((new Date(messages[index + 1].sent_at)) - (new Date(messages[index].sent_at))) > 300000) &&
                                ((moment(messages[index + 1].sent_at).fromNow()) !== (moment(messages[index].sent_at).fromNow()))
                            )
                        )
                    }
                    firstUserMessage={
                        (index === 0) ||
                        (
                            (messages[index].sender?.id !== messages[index - 1].sender?.id) ||
                            (
                                (((new Date(messages[index].sent_at)) - (new Date(messages[index - 1].sent_at))) > 300000) &&
                                ((moment(messages[index].sent_at).fromNow()) !== (moment(messages[index - 1].sent_at).fromNow()))
                            )
                        )
                    }
                />
            ))}

            {(canStream) ? (
                <>
                    {messageChunks && (
                        <Message
                            key={'message-' + messages.length}
                            index={messages.length}
                            initial={initial}
                            omitAnimation={true}
                            smallVersion={smallVersion}
                            assistantName={name}
                            message={{
                                id: -1,
                                sender: null,
                                sent_at: new Date().toISOString(),
                                message: messageChunks,
                                role: 'assistant',
                                answered: true,
                                chat: null,
                                is_invisible: false,
                                files: [],
                                read_by: [],
                            }}
                            lastUserMessage={false}
                            firstUserMessage={true}
                        />
                    )}
                </>
            ) : (
                <>
                    {building.map(user => (
                        <Box pl={smallVersion ? 1 : 0} pt={smallVersion ? 0 : 4} key={'typing-' + user}>
                            <Skeleton variant="rounded" sx={{borderRadius: '15px'}} width={218} height={33}/>
                            <Typography variant={smallVersion ? "caption" : "subtitle2"}>
                                {t('core.is_building', {name: user})}
                            </Typography>
                        </Box>
                    ))}
                    {building.length === 0 && thinking.map(user => (
                        <Box pl={smallVersion ? 1 : 0} pt={smallVersion ? 0 : 4} key={'typing-' + user}>
                            <Skeleton variant="rounded" sx={{borderRadius: '15px'}} width={218} height={33}/>
                            <Typography variant={smallVersion ? "caption" : "subtitle2"}>
                                {t('core.is_thinking', {name: user})}
                            </Typography>
                        </Box>
                    ))}
                    {(building.length === 0 && thinking.length === 0) && typing.map(user => (
                        <Box pl={smallVersion ? 1 : 0} pt={smallVersion ? 0 : 4} key={'typing-' + user}>
                            <Skeleton variant="rounded" sx={{borderRadius: '15px'}} width={218} height={33}/>
                            <Typography variant={smallVersion ? "caption" : "subtitle2"}>
                                {t('core.is_typing', {name: user})}
                            </Typography>
                        </Box>
                    ))}
                </>
            )}

            <div ref={messagesEndRef}/>
        </Stack>
    )

    // This is relevant for the small version
    if(height){
        return (
            <List sx={{height: height, overflow: 'auto', px: 2}}>
                {messageStack}
            </List>
        )
    }

    return (
        <Container maxWidth={maxWidth} sx={{pb: 20}} {...rest}>
            {messageStack}
        </Container>
    )
}

ChatAssistantViewer.propTypes = {
    smallVersion: PropTypes.bool,
    onUpdate: PropTypes.func,
    onAssistantNameChange: PropTypes.func,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', null]),
    height: PropTypes.number
}

ChatAssistantViewer.defaultProps = {
    smallVersion: false,
    onUpdate: null,
    onAssistantNameChange: null,
    maxWidth: false,
    height: null
}

export default ChatAssistantViewer;