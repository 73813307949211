import React, {useEffect, useState, memo} from "react";
import {
    Badge,
    Card,
    Dialog,
    DialogContent,
    Divider,
    Fab,
    IconButton,
    Stack, SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import {useLocation} from "react-router";
import ChatIcon from "@untitled-ui/icons-react/build/esm/MessageCheckCircle";
import ChannelIcon from "@untitled-ui/icons-react/build/esm/MessageChatCircle";
import {useDispatch, useSelector} from "react-redux";
import ChatWindow from "./ChatWindow";
import PropTypes from "prop-types";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import {
    addMiniThread, closeMiniThread,
    openMiniThread,
    removeNewMessages,
    storeNewMessages, updateAssistantOpen
} from "src/omnia/store/actions/messages-actions";
import _ from 'lodash';
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useAppMode} from "../../../../../hooks/use-app-mode";
import {ChatMessageAdd} from "../chat/chat-message-add";
import ChatAssistantViewer from "../chat/chat-assistant-viewer";
import CloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import Avatar from "@mui/material/Avatar";
import BlurredBox from "../../../../elements/blurred-box";

function ChatBarContent({miniMessages, thread, handleOpenChat}){

    const {user} = useSelector((state) => state.account);
    const [tooltip, setTooltip] = useState("")

    useEffect(() => {
        if(thread.users.length > 2){
            setTooltip(thread['title']);
        } else {
            const notUser = thread.users.filter(u => u.id !== user.id);
            if(notUser.length > 0){
                setTooltip(notUser[0].name);
            } else {
                setTooltip('');
            }
        }
    }, [thread, user]);

    if(thread.users.length > 2){
        return (
            <Tooltip
                title={thread['title']}
                placement="left"
            >
                <Badge
                    color="error"
                    badgeContent={thread['unread_count']}
                    overlap="circular"
                    sx={{
                        '& .MuiBadge-badge': {
                            zIndex: 1351
                        }
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Fab
                        color="primary"
                        onClick={() => handleOpenChat(thread)}
                        aria-label="add"
                    >
                        <ChannelIcon />
                    </Fab>
                </Badge>
            </Tooltip>
        )
    }

    return (
        <Tooltip
            title={tooltip}
            placement="left"
        >
            <Badge
                color="error"
                badgeContent={thread['unread_count']}
                overlap="circular"
                sx={{
                    '& .MuiBadge-badge': {
                        zIndex: 1351
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Fab
                    color="primary"
                    onClick={() => handleOpenChat(thread)}
                    aria-label="add"
                >
                    <Avatar
                        src={thread.users.filter(u => u.id !== user.id)?.[0]?.avatar?.view || null}
                        to={'/groon/profile/' + thread.users.filter(u => u.id !== user.id)?.[0]?.id}
                        sx={{width: 45, height: 45}}
                    >

                    </Avatar>
                </Fab>
            </Badge>
        </Tooltip>
    )

}

const AssistantChatWindow = memo(() => {

    const theme = useTheme();
    const { t } = useTranslation();
    const chatOpen = useSelector(state => state.messages.assistantOpen);
    const dispatch = useDispatch();
    const user = useSelector(state => state.account.user);
    const { uploadStatus, post } = useOmniaApi({autoError: false});
    const [ isSending, setIsSending ] = useState(false);
    const [ name, setName ] = useState('GROON Assistent');
    const { isMobile } = useIsMobile();

    const handleCloseChat = () => {
        dispatch(updateAssistantOpen(false));
    }

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setIsSending(true);
            post('assistant_chats/' + user?.current_assistant_chat + '/new_message', values).then(message => {
                resolve(message);
            }).catch(errors => {
                reject(errors);
            }).finally(() => {
                setIsSending(false);
            });
        });
    }

    const handleStopTyping = () => {
        // TODO: send this in the chat channel
    }

    const handleTyping = () => {
        // TODO: send this in the chat channel
    }

    if(!chatOpen)
        return null;

    const ChatHeader = (props) => {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                {...props}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Typography
                        variant="h6"
                        color="text.primary"
                    >
                        {name || 'GROON Assistent'}
                    </Typography>
                </Stack>
                <IconButton size="small" onClick={handleCloseChat}>
                    <SvgIcon fontSize="small">
                        <CloseIcon />
                    </SvgIcon>
                </IconButton>
            </Stack>
        )
    }

    const ChatFooter = (props) => {
        return (
            <ChatMessageAdd
                placeholder={t('core.how_can_i_help')}
                onSend={handleSend}
                size="small"
                uploadStatus={uploadStatus}
                smallVersion={true}
                sending={isSending}
                onTyping={handleTyping}
                onStopTying={handleStopTyping}
                {...props}
            />
        )
    }

    if(isMobile)
        return (
            <Dialog
                open={true}
                fullScreen={true}
            >
                <BlurredBox sx={{position: 'fixed', top: 0, height: 60, p: 2, width: '100%'}}>
                    <ChatHeader sx={{height: '100%', width: '100%'}} />
                </BlurredBox>
                <Divider />
                <DialogContent style={{paddingLeft: 0, paddingRight: 0}}>
                    <ChatAssistantViewer
                        onAssistantNameChange={name => setName(name)}
                        smallVersion={true}
                        sx={{pb: 20, pt: 10}}
                    />
                </DialogContent>
                <BlurredBox sx={{position: 'fixed', bottom: 0, height: 90, p: 2, width: '100%'}}>
                    <ChatFooter />
                </BlurredBox>
            </Dialog>
        )

    return (
        <Card style={{
            position: 'fixed',
            backgroundColor: theme.palette.background.default,
            width: 350,
            zIndex: 1500,
            bottom: 0,
            right: 65,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: theme.shadows[21],
        }}>
            <ChatHeader sx={{p: 2}} />
            <Divider />
            <ChatAssistantViewer
                onAssistantNameChange={name => setName(name)}
                smallVersion={true}
                height={400}
            />
            <ChatFooter />
        </Card>
    );

}, () => true);

function ChatBar({...rest}) {

    const location = useLocation();
    const theme = useTheme();
    const user = useSelector((state) => state.account.user);
    const dispatch = useDispatch();
    const threads = useSelector((state) => state.messages.threads);
    const assistantChatOpen = useSelector(state => state.messages.assistantOpen);
    const unreadAI = useSelector((state) => state.messages.unreadAI);
    const miniThreads = useSelector((state) => state.messages.miniThreads);
    const newMessages = useSelector((state) => state.messages.newMessages);
    const openMini = useSelector((state) => state.messages.openMiniThread);
    const { isScreenVisible } = useNotifications();
    const [miniMessages, setMiniMessages] = useState({});
    const { isBackend } = useAppMode();
    const { isMobile } = useIsMobile();
    const [miniMessagesTimers, setMiniMessagesTimers] = useState({});
    const aiActive = theme?.config?.aiOn || false;
    const { flashDesktopNotification } = useNotifications();

    const removeLastMessageTooltip = (thread) => {
        // remove last message
        setMiniMessages(prev => {
            return _.omit(prev, [thread.id]);
        })
    }

    const handleOpenAssistantChat = () => {
        dispatch(closeMiniThread());
        dispatch(updateAssistantOpen(true));
    }

    const handleOpenChat = (thread) => {
        dispatch(updateAssistantOpen(false));
        dispatch(openMiniThread(thread));
        removeLastMessageTooltip(thread);
    }

    useEffect(() => {

        // iterate through all new messages
        for(let i = 0; i < newMessages.length; i++){

            // check if user is currently in chat
            let inChat = ("/groon/chat/" + newMessages[i].thread === location.pathname);
            let miniChat = openMini ? (openMini.id === newMessages[i].thread) : false;
            let miniChatExists = miniThreads.includes(newMessages[i].thread);

            // check if the thread is already mini thread
            if(!miniChatExists)
                dispatch(addMiniThread(newMessages[i].thread));

            // flash desktop notifications if nessesarry
            if(!isScreenVisible()){
                // create message
                let desktopMessage = (newMessages[i].content_type === "text") ? newMessages[i].body : 'Datei' ;
                // flash the created message on the desktop
                flashDesktopNotification(desktopMessage, "Nachricht von " + newMessages[i].sender.name);
            }

            // get thread object from redux store
            let object = threads.find(t => t.id === newMessages[i].thread);
            if(typeof(object) !== "undefined"){

                // check if notification should be thrown
                if(!(inChat || miniChat) && !object.muted){

                    // set to last messages
                    setMiniMessages(prev => {
                        prev[newMessages[i].thread] = newMessages[i];
                        return prev;
                    });

                    // handle timers
                    setMiniMessagesTimers(prev => {
                        if(typeof(prev[newMessages[i].thread]) !== "undefined")
                            clearTimeout(prev[newMessages[i].thread]);
                        prev[newMessages[i].thread] = setTimeout(() => {
                            removeLastMessageTooltip(threads.filter(t => t.id === newMessages[i].thread)[0]);
                        }, 5000);
                        return prev;
                    })

                }
            }

            // put message into redux
            dispatch(storeNewMessages(newMessages[i].thread, [newMessages[i]], (inChat || miniChat)));

        }

        // remove all new messages from queue
        if(newMessages.length > 0)
            dispatch(removeNewMessages());

    }, [newMessages]);

    useEffect(() => {
        // TODO: do something with timers?
    }, [miniMessagesTimers]);

    if (location.pathname.includes('/groon/launchpad') || location.pathname.includes('/groon/assistant') || location.pathname.includes('chat') || !user || !isBackend)
        return null;

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1350,
                    bottom: 10,
                    right: 10
                }}
                {...rest}
            >
                <Stack spacing={1} sx={{mb: 1}}>
                    {threads.filter(t => miniThreads.includes(t.id)).map((thread, i) => (
                        <ChatBarContent
                            key={'thread_' + i}
                            miniMessages={miniMessages}
                            thread={thread}
                            handleOpenChat={handleOpenChat}
                        />
                    ))}
                </Stack>
                {(aiActive && !isMobile && (location.pathname !== '/groon/launchpad')) && (
                    <Tooltip
                        title="Schreibe eine Nachricht an deinen Assistenten"
                        placement="left"
                    >
                        <Badge
                            color="error"
                            badgeContent={unreadAI}
                            overlap="circular"
                            sx={{
                                '& .MuiBadge-badge': {
                                    zIndex: 1351
                                }
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Fab
                                color="primary"
                                onClick={handleOpenAssistantChat}
                                aria-label="add"
                            >
                                <SvgIcon fontSize="small">
                                    <ChatIcon />
                                </SvgIcon>
                            </Fab>
                        </Badge>
                    </Tooltip>
                )}
            </div>

            <ChatWindow />

            <AssistantChatWindow />
        </>
    )
}

ChatBarContent.propTypes = {
    miniMessages: PropTypes.array,
    thread: PropTypes.object,
    handleOpenChat: PropTypes.func
}

export default ChatBar;