import React, { useState } from "react";
import { styled, useTheme } from "@mui/system";
import { Tooltip, Box, Typography, SvgIcon, Stack, Button } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse, { domToReact } from 'html-react-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@untitled-ui/icons-react/build/esm/Copy01';
import { useTranslation } from "react-i18next";
import { marked } from 'marked';
import PropTypes from 'prop-types';
import Linkify from "react-linkify";

function HtmlWrapper({ html, styles, linkColor, selectionColor, selectionBackground, ...rest }) {
    const theme = useTheme();

    const CustomSelectionBox = styled(Box)({
        '::selection': {
            background: selectionBackground ? selectionBackground : theme.palette.text.primary,
            color: selectionColor ? selectionColor : theme.palette.primary.main,
            // background: '#ffffff',
            // color: 'red'
        },
        '::-moz-selection': {
            background: selectionBackground ? selectionBackground : theme.palette.text.primary,
            color: selectionColor ? selectionColor : theme.palette.primary.main,
            // background: '#ffffff',
            // color: 'red'
        }
    });

    const getStyles = theme => ({
        fontFamily: `Font-${theme?.config?.id}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        fontSize: (theme?.config?.font_subtitle1_size || 12) + 'px',
        fontWeight: theme?.config?.font_subtitle1_weight || 400,
        '& h1': {
            fontWeight: theme?.config?.font_h1_weight || 700,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h2': {
            fontWeight: theme?.config?.font_h2_weight || 700,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h3': {
            fontWeight: theme?.config?.font_h3_weight || 700,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h4': {
            fontWeight: theme?.config?.font_h4_weight || 700,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& p': {
            marginBlockEnd: '0 !important',
            marginBlockStart: '0 !important',
        },
        '& ol': {
            paddingLeft: '20px !important',
            marginBottom: '10px !important',
            marginTop: '10px !important',
        },
        '& li': {
            marginBottom: '0px !important',
        },
        '& ul': {
            paddingLeft: '20px !important',
            marginBottom: '10px !important',
            marginTop: '10px !important',
        },
        ...styles
    });

    const StyledDiv = styled('div')(() => getStyles(theme));

    const CodeBlock = ({ language, value }) => {
        const [copied, setCopied] = useState(false);
        const { t } = useTranslation();

        const handleCopy = () => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        };

        const backgorundColor = theme?.config?.dark_discrete;
        const headerColor = theme?.config?.dark_evident;

        return (
            <div style={{ position: 'relative', borderRadius: (theme?.config?.card_radius || 10) + 'px', backgroundColor: backgorundColor, marginTop: '10px', marginBottom: '10px' }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    py={0}
                    sx={{
                        borderTopLeftRadius: (theme?.config?.card_radius || 10) + 'px',
                        borderTopRightRadius: (theme?.config?.card_radius || 10) + 'px',
                        backgroundColor: headerColor
                    }}
                >
                    <Typography variant="body1" color="white">
                        {language}
                    </Typography>
                    <CopyToClipboard text={value} onCopy={handleCopy}>
                        <Tooltip title={copied ? t('common.copied') : t('common.copy')} placement="top">
                            <Button size="small" style={{ color: 'white' }}>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{mx: 1}}>
                                    <SvgIcon fontSize="small">
                                        <FileCopyIcon />
                                    </SvgIcon>
                                    <Typography variant="body1">
                                        {t('common.copy_code')}
                                    </Typography>
                                </Stack>
                            </Button>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
                <SyntaxHighlighter
                    language={language}
                    style={vscDarkPlus}
                    customStyle={{ borderRadius: (theme?.config?.card_radius || 10) + 'px', backgroundColor: backgorundColor }}
                    codeTagProps={{ style: { backgroundColor: backgorundColor } }}
                    CodeTag={({ className, children }) => (
                        <code
                            className={className}
                            style={{
                                fontSize: (theme?.config?.font_subtitle1_size || 12) + 'px',
                                fontWeight: theme?.config?.font_subtitle1_weight || 400,
                            }}
                        >
                            {children}
                        </code>
                    )}
                >
                    {value}
                </SyntaxHighlighter>
            </div>
        );
    };

    CodeBlock.defaultProps = {
        language: 'plaintext',
        value: ''
    }

    CodeBlock.propTypes = {
        language: PropTypes.string,
        value: PropTypes.string
    }

    const getCommentedTag = (tag, language) => {
        switch (language) {
            case 'javascript':
            case 'typescript':
                return `/* ${tag} */`;
            case 'python':
                return `# ${tag}`;
            case 'ruby':
                return `# ${tag}`;
            case 'php':
                return `/* ${tag} */`;
            case 'java':
            case 'csharp':
                return `// ${tag}`;
            default:
                return `<!-- ${tag} -->`;
        }
    };

    const getCode = (node, language) => {
        return node.children.map(child => {
            // Process text in the code block (simply code)
            if (child.type === 'text') {
                return child.data;
            }

            // Process HTML tags in the code block
            if (child.type === 'tag') {
                const tagAsString = getCode(child, language);
                return getCommentedTag(tagAsString, language);
            }

            // Fallback, simply return the processed child
            return getCode(child);
        }).join('');
    }

    const customComponentDecorator = (decoratedHref, decoratedText, key) => (
        <a href={decoratedHref} key={key} style={{ color: linkColor ? linkColor : theme.palette.primary.main }} target="_blank" rel="noopener noreferrer">
            {decoratedText}
        </a>
    );

    const renderHtml = (html) => {
        if (!html) return null;

        return parse(marked(html), {
            replace: domNode => {
                if (domNode.name === 'pre' && domNode.children && domNode.children.length) {
                    const codeNode = domNode.children.find(child => child.name === 'code');
                    if (codeNode && codeNode.children.length) {
                        const language = codeNode.attribs.class?.replace('language-', '') || '';

                        // Get the finally processed code node
                        const code = getCode(codeNode, language);

                        return (
                            <CodeBlock
                                language={language}
                                value={code}
                            />
                        );
                    }
                }
                return domToReact(domNode);
            }
        });
    };

    return (
        <StyledDiv styles={getStyles(theme)} {...rest}>
            <CustomSelectionBox>
                <Linkify componentDecorator={customComponentDecorator}>
                    {renderHtml(html)}
                </Linkify>
            </CustomSelectionBox>
        </StyledDiv>
    )
}

HtmlWrapper.defaultProps = {
    styles: {},
    linkColor: null,
    selectionColor: null,
    selectionBackground: null,
}

HtmlWrapper.propTypes = {
    html: PropTypes.string.isRequired,
    linkColor: PropTypes.string,
    selectionColor: PropTypes.string,
    selectionBackground: PropTypes.string,
    styles: PropTypes.object
}

export default HtmlWrapper;
