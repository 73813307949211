import React, {useRef, useState} from "react";
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    SvgIcon,
    TextField,
    Typography
} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {useDialog} from "../../../hooks/use-dialog";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import {FileDropzone} from "../../elements/file-dropzone";
import SaveButton from "../../elements/save-button";
import OnIcon from "../../elements/icon";
import {useIsMobile} from "../../../hooks/use-is-mobile";

function CreateUserRequest(){

    const dialog = useDialog();
    const { post } = useOmniaApi();
    const { notify } = useNotifications();
    const { isMobile } = useIsMobile();
    const { hasRights } = useSecurityCheck();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            kind: 'bug',
            description: '',
            files: []
        },
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            post('omnia_requests', values).then(() => {
                helpers.resetForm();
                dialog.handleClose();
                notify(t("common.thank_you"), "success");
            }).finally(() => {
                helpers.setSubmitting(false);
            });

        }
    })

    const labels = {
        feature: t("common.feature_request"),
        change: t("common.change_request"),
        bug: t("common.bug_report"),
        other: t("common.other")
    }

    const placeholder = {
        feature: t("common.feature_request_placeholder"),
        change: t("common.change_request_placeholder"),
        bug: t("common.bug_report_placeholder"),
        other: t("common.other_placeholder")
    }

    const openRequest = () => {
        dialog.handleOpen(null);
    }

    return (
        <>
            <Dialog
                open={dialog.open}
                onClose={dialog.handleClose}
                fullWidth={true}
                fullScreen={isMobile}
                maxWidth={'sm'}
            >
                <DialogTitle>
                    <Typography variant="h4">
                        {t('dialogs.open_ticket.title')}
                    </Typography>
                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        {t('dialogs.open_ticket.description')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box mb={6} mt={4}>
                        <TextField
                            select
                            fullWidth
                            label={t("attributes.kind")}
                            name="kind"
                            onChange={formik.handleChange}
                            value={formik.values.kind}
                        >
                            {hasRights('on_feature_request') && (
                                <MenuItem value="feature">{labels['feature']}</MenuItem>
                            )}
                            {hasRights('on_change_request') && (
                                <MenuItem value="change">{labels['change']}</MenuItem>
                            )}
                            {hasRights('on_bug_report') && (
                                <MenuItem value="bug">{labels['bug']}</MenuItem>
                            )}
                            {hasRights('on_other_request') && (
                                <MenuItem value="other">{labels['other']}</MenuItem>
                            )}
                        </TextField>
                        <TextField
                            fullWidth
                            autoFocus={true}
                            label={labels[formik.values.kind]}
                            placeholder={placeholder[formik.values.kind]}
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            multiline
                            rows={4}
                            sx={{mt: 4}}
                        />
                    </Box>
                    <FileDropzone
                        height={200}
                        showButtons={false}
                        files={formik.values.files}
                        onDrop={(newFiles) => formik.setFieldValue('files', [...formik.values.files, ...newFiles])}
                        onRemove={(file) => formik.setFieldValue('files', formik.values.files.filter(f => f !== file))}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2, width: '100%' }}>
                        <Button onClick={dialog.handleClose} variant="outlined">{t('common.cancel')}</Button>
                        <SaveButton
                            color="primary"
                            variant="contained"
                            loading={formik.isSubmitting}
                            onClick={formik.handleSubmit}
                            label={t("common.send")}
                            noIcon
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
            <Box sx={{p: 2}}>
                <Typography variant="subtitle1">
                    {t("common.support")}
                </Typography>
                <Typography
                    color="neutral.400"
                    sx={{mb: 2}}
                    variant="body2"
                >
                    {t("common.how_can_we_help")}
                </Typography>
                <Button
                    onClick={openRequest}
                    fullWidth
                    color="primary"
                    startIcon={<OnIcon iconName="Annotation" size="small" />}
                    variant="contained"
                >
                    {t("common.create_request")}
                </Button>
            </Box>
        </>
    )
}

export default CreateUserRequest;